import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faTwitter, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons"

const TopBar = () => {
  return (
    <div className="top-bar clearfix">
      <div className="container">
        <div className="top-bar-inner">
          <ul className="social-links">
            <li className="social-links-item">
              <a href="https://www.facebook.com/New-England-Hurricanes-Paintball-301360889244/" className="social-links-link">
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
            </li>
            {/* <li className="social-links-item"> */}
            {/*   <a href="#" className="social-links-link"> */}
            {/*     <FontAwesomeIcon icon={faTwitter} /> */}
            {/*   </a> */}
            {/* </li> */}
            <li className="social-links-item">
              <a href="https://www.instagram.com/newenglandhurricanes/" className="social-links-link">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            {/* <li className="social-links-item"> */}
            {/*   <a href="#" className="social-links-link"> */}
            {/*     <FontAwesomeIcon icon={faYoutube} /> */}
            {/*   </a> */}
            {/* </li> */}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default TopBar;
