import React from 'react'
import './header-mobile.css'

const HeaderMobile = ({ setDrawerOpen }) => {
  return (
    <div className="header-mobile clearfix" id="header-mobile">
      <div className="header-mobile-logo">
        <img
          src="https://i.imgur.com/aVxlGuw.png"
          alt="Hurricanes Logo"
          className="header-mobile-logo-img"
        />
      </div>
      <div className="header-mobile-inner">
        <a id="header-mobile-target" className="in-and-out-icon" onClick={() => setDrawerOpen(true)}>
          <span className="in-and-out-burger"></span>
        </a>
      </div>
    </div>
  )
}

export default HeaderMobile
