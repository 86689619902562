import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import './article-preview.css'

export default ({ article }) => (
  <div className="post col-sm-6">
    <div className="post-item card">
      <figure className="post-thumb">
        <div className="post-tags">
          {article.tags.map(tag => (
            <span className="badge badge-secondary" key={tag}>
              {tag}
            </span>
          ))}
        </div>
        <Link to={`/blog/${article.slug}`}>
          <Img alt={article.slug} fluid={article.heroImage.fluid} />
        </Link>
      </figure>
      <div className="post-content">
        <time className="post-date" dateTime={article.publishDate}>{article.publishDate}</time>
        <h6 className="preview-title">
          <Link to={`/blog/${article.slug}`}>{article.title}</Link>
        </h6>
        <p
          dangerouslySetInnerHTML={{
            __html: article.description.childMarkdownRemark.html,
          }}
        />
      </div>
    </div>
  </div>
)
