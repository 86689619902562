import React, { useState } from 'react'
import { Link } from 'gatsby'
import './bootstrap.min.css'
import base from './base.css'
import Container from './container'
import Navigation from './navigation'
import HeaderMobile from './header-mobile'

const Template = ({ location, children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  let header;

  let rootPath = `/`
  if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
    rootPath = __PATH_PREFIX__ + `/`
  }

  return (
    <Container>
        {drawerOpen && <div className="site-overlay"></div>}
        <HeaderMobile setDrawerOpen={setDrawerOpen} />
        <Navigation drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        <br />
          {children}
      </Container>
  )
}

export default Template
