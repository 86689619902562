import React from 'react'
import { Link } from 'gatsby'
import TopBar from './top-bar'
import './header.css'

const Header = ({ drawerOpen, setDrawerOpen }) => {
  return (
    <header className="header"style={ drawerOpen? null : {display: 'none'}}>
      <TopBar />
      <div className="header-primary" >
        <div className="container">
          <div className="header-primary-inner">
            <div className="header-logo">
              <img
                src="https://i.imgur.com/aVxlGuw.png"
                alt="Hurricanes Logo"
                className="header-logo-img"
              />
            </div>
            <nav className={`main-nav ${drawerOpen && "main-nav-open"}`}>
              <div className="text-center">
                <span className="main-nav-back" onClick={() => setDrawerOpen(false)}></span>
                <img
                  src="https://i.imgur.com/aVxlGuw.png"
                  alt="Hurricanes Logo"
                  className="header-logo-img-small"
                />
              </div>
              <ul className="main-nav-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/">The Team</Link>
                </li>
                <li>
                  <Link to="/">Partners</Link>
                </li>
                <li>
                  <Link to="/">Shop</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
